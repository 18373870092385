import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent(
      'SliderGallery',
      () => {
        return import(
          './viewer/skinComps/SliderGalleryDefaultSkin/SliderGalleryDefaultSkin.skin' /* webpackChunkName: "SliderGallery_SliderGalleryDefaultSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'SliderGalleryDefaultSkin',
    );
  },
};

export default entry;
